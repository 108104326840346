import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Teams.css';
import TeamMembers from './TeamMembers'; // Import the TeamMembers component

function Teams() {
  const [teams, setTeams] = useState([]);
  const [teamName, setTeamName] = useState('');
  const [users, setUsers] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState('');
  const [selectedUser, setSelectedUser] = useState('');
  const [showMembers, setShowMembers] = useState({});
  const [userRole, setUserRole] = useState(''); // State to track user role

  useEffect(() => {
    // Simulated authentication logic, replace with actual authentication logic
    const userRole = authenticateUser(); // Function to authenticate user and retrieve role
    setUserRole(userRole);
    if (userRole) {
      fetchTeams();
      fetchUsers();
    }
  }, []);

  const authenticateUser = () => {
    // Placeholder for authentication logic, replace with actual implementation
    // Check if user is authenticated and retrieve user role
    // Example:
    // const userToken = localStorage.getItem('userToken');
    // if (userToken) {
    //   const decodedToken = decodeToken(userToken); // Function to decode JWT token
    //   return decodedToken.role;
    // }
    // return null; // If user is not authenticated
    return 'superadmin'; // Simulated user role for demo purposes
  };

  const fetchTeams = async () => {
    try {
      const response = await axios.get('https://217.21.84.160:3001/teams');
      setTeams(response.data);
    } catch (error) {
      console.error('Error fetching teams:', error);
    }
  };

  const fetchUsers = async () => {
    try {
      const response = await axios.get('https://217.21.84.160:3001/users');
      setUsers(response.data);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  const handleCreateTeam = async () => {
    try {
      await axios.post('https://217.21.84.160:3001/teams', { name: teamName });
      fetchTeams();
      setTeamName('');
    } catch (error) {
      console.error('Error creating team:', error);
    }
  };

  const handleAddUserToTeam = async () => {
    try {
      await axios.post(`https://217.21.84.160:3001/teams/${selectedTeam}/users`, { userId: selectedUser });
      fetchTeams();
    } catch (error) {
      console.error('Error adding user to team:', error);
    }
  };

  const toggleMembers = (teamId) => {
    setShowMembers(prevState => ({
      ...prevState,
      [teamId]: !prevState[teamId]
    }));
  };

  return (
    <div>
      <h2>Team Management</h2>
      {userRole === 'superadmin' && (
        <div>
          <h3>Create New Team</h3>
          <input
            type="text"
            placeholder="Enter team name"
            value={teamName}
            onChange={(e) => setTeamName(e.target.value)}
          />
          <button onClick={handleCreateTeam}>Create</button>
        </div>
      )}
      <div>
        <h3>Add User to Team</h3>
        <select value={selectedTeam} onChange={(e) => setSelectedTeam(e.target.value)}>
          <option value="">Select Team</option>
          {teams.map(team => (
            <option key={team.id} value={team.id}>{team.name}</option>
          ))}
        </select>
        <select value={selectedUser} onChange={(e) => setSelectedUser(e.target.value)}>
          <option value="">Select User</option>
          {users.map(user => (
            <option key={user.id} value={user.id}>{user.name}</option>
          ))}
        </select>
        <button onClick={handleAddUserToTeam}>Add User to Team</button>
      </div>
      <div>
        <h3>Teams</h3>
        {userRole === 'superadmin' && (
          <table>
            <thead>
              <tr>
                <th>ID</th>
                <th>Name</th>
                <th>Members</th>
              </tr>
            </thead>
            <tbody>
              {teams.map(team => (
                <tr key={team.id}>
                  <td>{team.id}</td>
                  <td>{team.name}</td>
                  <td onClick={() => toggleMembers(team.id)}>View Members</td>
                  {showMembers[team.id] && (
                    <td>
                      <TeamMembers teamId={team.id} />
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        )}
        {userRole === 'user' && (
          <ul>
            {teams.map(team => (
              <li key={team.id} onClick={() => toggleMembers(team.id)}>
                {team.name}
                {showMembers[team.id] && <TeamMembers teamId={team.id} />}
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
}

export default Teams;
