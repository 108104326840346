  import React, { useState } from 'react';
  import './form.css';

  function MembershipForm() {
  const [formData, setFormData] = useState({
      website_link: '',
      website_password: '',
      membership_type: 'starter',
      website_tagline: '',
      reference_website_link: '',
      additional_note: '',
      number_of_pages: '1',
      marketing_services: 'Yes',
      starting_date: '',
      deadline_date: '',
      first_name: '',
      place_of_birth: '',
      date_of_birth: '',
      full_address: '',
      phone_number: '',
      email: '',
      nationality: '',
      postcode: '',
      religion: '',
      gender: '',
      city_country:'',
      role: 'subscriber'
  });

  // Function to handle changes in form inputs
  const handleChange = (event) => {
      const { name, value } = event.target;
      setFormData(prevState => ({
        ...prevState,
        [name]: value,
      }));
  };

  // Function to handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await fetch("https://217.21.84.160:3001/submit-form", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });
      if (!response.ok) {
        throw new Error(`https error! status: ${response.status}`);
      }
      console.log('Form data submitted successfully');
      // Optionally, reset form state here
      setFormData({
        website_link: '',
        website_password: '',
        membership_type: 'starter',
        website_tagline: '',
        reference_website_link: '',
        additional_note: '',
        number_of_pages: '1',
        marketing_services: 'Yes',
        starting_date: '',
        deadline_date: '',
        first_name: '',
        place_of_birth: '',
        date_of_birth: '',
        full_address: '',
        phone_number: '',
        email: '',
        nationality: '',
        postcode: '',
        religion: '',
        gender: '',
        city_country:'',
        role: 'subscriber'
      });
    } catch (error) {
      console.error('Error submitting form data:', error);
    }
  };

  return (
      <div className="container">
        <h1>Membership Form</h1>
        <form onSubmit={handleSubmit}>
        <div className="form-group">
              <label htmlFor="website_link"><b>Website Link</b></label>
              <input type="text" id="website_link" placeholder="Enter Website Link" name="website_link" value={formData.website_link} onChange={handleChange} required />
            </div>
            <div className="form-group">
              <label htmlFor="website_password"><b>Website Password</b></label>
              <input type="password" id="website_password" placeholder="Enter Website Password" name="website_password" value={formData.website_password} onChange={handleChange} required />
            </div>
            <div className="form-group">
              <label htmlFor="website_tagline"><b>Website Tagline</b></label>
              <input type="text" id="website_tagline" placeholder="Enter Website Tagline" name="website_tagline" value={formData.website_tagline} onChange={handleChange} required />
            </div>
            <div className="form-group">
              <label htmlFor="reference_website_link"><b>Reference Website Link</b></label>
              <input type="text" id="reference_website_link" placeholder="Enter Reference Website Link" name="reference_website_link" value={formData.reference_website_link} onChange={handleChange} required />
            </div>
            <div className="form-group">
              <label htmlFor="additional_note"><b>Additional Note</b></label>
              <input type="text" id="additional_note" placeholder="Enter Additional Note" name="additional_note" value={formData.additional_note} onChange={handleChange} required />
            </div>
            <label htmlFor="number_of_pages"><b>Number of Pages</b></label>
            <select id="number_of_pages" name="number_of_pages" value={formData.number_of_pages} onChange={handleChange}>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
            </select>
            <label htmlFor="marketing_services"><b>Marketing Services</b></label>
            <select id="marketing_services" name="marketing_services" value={formData.marketing_services} onChange={handleChange}>
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </select>
            <div className="form-group1">
              <label><b>Starting Date:</b></label>
              <input type="date" id="starting_date" name="starting_date" value={formData.starting_date} onChange={handleChange} />
              <label><b>Deadline Date:</b></label>
              <input type="date" id="deadline_date" name="deadline_date" value={formData.deadline_date} onChange={handleChange} />
            </div>
          <fieldset>
            <legend>Personal Information</legend>
            <div className="form-group">
              <label htmlFor="first_name"><b>First Name</b></label>
              <input type="text" id="first_name" placeholder="Enter First Name" name="first_name" value={formData.first_name} onChange={handleChange} required />
            </div>
            <div className="form-group">
              <label htmlFor="place_of_birth"><b>Place of Birth</b></label>
              <input type="text" id="place_of_birth" placeholder="Enter Place of Birth" name="place_of_birth" value={formData.place_of_birth} onChange={handleChange} required />
            </div>
            <div className="form-group">
              <label htmlFor="date_of_birth"><b>Date of Birth</b></label>
              <input type="date" id="date_of_birth" name="date_of_birth" value={formData.date_of_birth} onChange={handleChange} required />
            </div>
            <div className="form-group">
              <label htmlFor="full_address"><b>Full Address</b></label>
              <input type="text" id="full_address" placeholder="Enter Full Address" name="full_address" value={formData.full_address} onChange={handleChange} required />
            </div>
            <div className="form-group">
              <label htmlFor="phone_number"><b>Phone Number</b></label>
              <input type="text" id="phone_number" placeholder="Enter Phone Number" name="phone_number" value={formData.phone_number} onChange={handleChange} required />
            </div>
            <div className="form-group">
              <label htmlFor="email"><b>Email</b></label>
              <input type="email" id="email" placeholder="Enter Email" name="email" value={formData.email} onChange={handleChange} required />
            </div>
            <div className="form-group">
              <label htmlFor="nationality"><b>Nationality</b></label>
              <input type="text" id="nationality" placeholder="Enter Nationality" name="nationality" value={formData.nationality} onChange={handleChange} required />
            </div>
            <div className="form-group">
              <label htmlFor="postcode"><b>Postcode</b></label>
              <input type="text" id="postcode" placeholder="Enter Postcode" name="postcode" value={formData.postcode} onChange={handleChange} required />
            </div>
            <div className="form-group">
              <label htmlFor="religion"><b>Religion</b></label>
              <input type="text" id="religion" placeholder="Enter Religion" name="religion" value={formData.religion} onChange={handleChange} required />
            </div>
            <div className="form-group">
              <label htmlFor="gender"><b>Gender</b></label>
              <select id="gender" name="gender" value={formData.gender} onChange={handleChange} required>
                <option value="">Select Gender</option>
                <option value="male">Male</option>
                <option value="female">Female</option>
                <option value="other">Other</option>
              </select>
            </div>

            <div className="form-group">
              <label htmlFor="city_country"><b>City/Country</b></label>
              <input type="text" id="city_country" placeholder="Enter City/Country" name="city_country" value={formData.city_country} onChange={handleChange} required />
            </div>
            <div className="form-group">
              <label htmlFor="role"><b>Role</b></label>
              <select id="role" name="role" value={formData.role} onChange={handleChange}>
                <option value="subscriber">Subscriber</option>
                <option value="author">Author</option>
                <option value="customer">Customer</option>
                <option value="administration">Administration</option>
              </select>
            </div>
          
          </fieldset>
          <button type="submit">Submit</button>
        </form>
      </div>
    );
  }

  export default MembershipForm;
