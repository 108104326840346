import React, { useState, useEffect } from 'react';
import axios from 'axios';

function UserManagement() {
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState('');
  const [selectedRole, setSelectedRole] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get('https://217.21.84.160:3001/users');
      setUsers(response.data);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching users:', error);
      setIsLoading(false);
    }
  };

  const handlePromoteToSuperadmin = async (userId) => {
    try {
      setIsLoading(true);
      await axios.put(`https://217.21.84.160:3001/users/${userId}/set_superadmin`);
      const updatedUsers = users.map(user => {
        if (user.id === userId) {
          return { ...user, role: 'superadmin' };
        }
        return user;
      });
      setUsers(updatedUsers);
      setIsLoading(false);
    } catch (error) {
      console.error('Error promoting user to superadmin:', error);
      setIsLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      await axios.post(`https://217.21.84.160:3001/users/update_role`, { userId: selectedUser, role: selectedRole });
      fetchUsers();
      setSelectedUser('');
      setSelectedRole('');
      setIsLoading(false);
    } catch (error) {
      console.error('Error updating user role:', error);
      setIsLoading(false);
    }
  };

  return (
    <div>
      <h2>User Management</h2>
      {isLoading ? (
        <p>Loading...</p>
      ) : (
        <div>
          <h3>User List</h3>
          <table style={{ borderCollapse: 'collapse', width: '100%' }}>
            <thead>
              <tr style={{ backgroundColor: '#f2f2f2' }}>
                <th style={{ border: '1px solid #dddddd', textAlign: 'left', padding: '8px' }}>Username</th>
                <th style={{ border: '1px solid #dddddd', textAlign: 'left', padding: '8px' }}>Role</th>
                <th style={{ border: '1px solid #dddddd', textAlign: 'left', padding: '8px' }}>Action</th>
              </tr>
            </thead>
            <tbody>
              {users.map(user => (
                <tr key={user.id}>
                  <td style={{ border: '1px solid #dddddd', textAlign: 'left', padding: '8px' }}>{user.username}</td>
                  <td style={{ border: '1px solid #dddddd', textAlign: 'left', padding: '8px' }}>{user.role}</td>
                  <td style={{ border: '1px solid #dddddd', textAlign: 'left', padding: '8px' }}>
                    {user.role !== 'superadmin' && (
                      <button style={{ backgroundColor: '#4CAF50', color: 'white', padding: '8px 16px', fontSize: '14px', cursor: 'pointer', border: 'none' }} onClick={() => handlePromoteToSuperadmin(user.id)}>Promote to Superadmin</button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <h3>Update User Role</h3>
          <form onSubmit={handleSubmit} style={{ marginTop: '20px' }}>
            <select value={selectedUser} onChange={(e) => setSelectedUser(e.target.value)} style={{ padding: '8px', marginRight: '10px' }}>
              <option value="">Select User</option>
              {users.map(user => (
                <option key={user.id} value={user.id}>{user.username}</option>
              ))}
            </select>
            <select value={selectedRole} onChange={(e) => setSelectedRole(e.target.value)} style={{ padding: '8px', marginRight: '10px' }}>
              <option value="">Select Role</option>
              <option value="admin">Admin</option>
              <option value="user">User</option>
            </select>
            <button type="submit" style={{ backgroundColor: '#008CBA', color: 'white', padding: '8px 16px', fontSize: '14px', cursor: 'pointer', border: 'none' }}>Update Role</button>
          </form>
        </div>
      )}
    </div>
  );
}

export default UserManagement;
