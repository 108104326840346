import React, { useState, useEffect } from 'react';
import './LiveProject.css'; // Import the CSS file
import axios from 'axios'; // Import axios for making https requests

const LiveProject = () => {
  const [websiteLinks, setWebsiteLinks] = useState([]); // State to store website links
  const [error, setError] = useState(null); // State to store error
  const [statusUpdated, setStatusUpdated] = useState(false); // State to track status update

  useEffect(() => {
    const fetchWebsiteLinks = async () => {
      try {
        // Make an https GET request to fetch website links from the server-side API
        const response = await axios.get('https://217.21.84.160:3001/membership_form/website_links');
        console.log('Response:', response); // Debugging statement

        if (response.status === 200) {
          // If request is successful, set the fetched website links in state
          setWebsiteLinks(response.data);
        } else {
          console.error('Failed to fetch website links:', response.statusText);
          setError('Failed to fetch website links');
        }
      } catch (error) {
        console.error('Error fetching website links:', error);
        setError('Error fetching website links');
      }
    };

    fetchWebsiteLinks(); // Call the fetchWebsiteLinks function when component mounts
  }, []);

  console.log('Website Links:', websiteLinks); // Debugging statement

  const handleStatusChange = async (index, event) => {
    const newWebsiteLinks = [...websiteLinks];
    newWebsiteLinks[index] = {
      ...newWebsiteLinks[index],
      development_status: event.target.value // Update development status
    };
    setWebsiteLinks(newWebsiteLinks);

    try {
      // Make an https POST request to update the development status in the database
      await axios.post('https://217.21.84.160:3001/membership_form/update_status', {
        websiteLink: newWebsiteLinks[index].website_link,
        developmentStatus: event.target.value 
      });
      console.log('Status updated successfully');
      setStatusUpdated(true); // Set statusUpdated to true after successful update
    } catch (error) {
      console.error('Error updating status:', error);
      setStatusUpdated(false); // Reset statusUpdated on error
    }
  };

  return (
    <div className="your-work">
      <h1>Live Project</h1>
      <div className="task-card">
        {error && <div>Error: {error}</div>} {/* Display error message */}
        {statusUpdated && <div>Status updated successfully</div>} {/* Prompt for successful status update */}
        <table>
          <thead>
            <tr>
              <th>Project Name</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {/* Map over the websiteLinks array and render each website link with its development status */}
            {websiteLinks.map((link, index) => (
              <tr key={index}>
                <td><a href={link.website_link}>{link.website_link}</a></td>
                <td>
                  <select value={link.development_status} onChange={(event) => handleStatusChange(index, event)}>
                    <option value="Pending">Pending</option>
                    <option value="In Progress">In Progress</option>
                    <option value="Completed">Completed</option>
                  </select>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default LiveProject;
