import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import './CustomerDetails.css'; // Import the CSS file
import MembershipFormList from './MembershipFormList'; // Import the MembershipFormList component

const CustomerDetails = () => {
  const [customer, setCustomer] = useState(null);
  const [forms, setForms] = useState([]);
  const { id } = useParams(); // Get the ID parameter from the URL

  useEffect(() => {
    const fetchCustomerDetails = async () => {
      try {
        // Make an https GET request to fetch the customer details by ID
        const response = await fetch(`https://217.21.84.160:3001/membership_forms/${id}`);
        if (response.ok) {
          const data = await response.json();
          setCustomer(data);
        } else {
          console.error('Failed to fetch customer details:', response.statusText);
        }
      } catch (error) {
        console.error('Network error:', error.message);
      }
    };

   

    fetchCustomerDetails();
   
  }, [id]); // Fetch customer details whenever the ID parameter changes

  return (
    <div className="customer-details-container">
      <h2>Customer Details</h2>
      {customer && (
        <div>
          <strong>ID:</strong> {customer.id}<br />
          <strong>First Name:</strong> {customer.first_name}<br />
          <strong>Place of Birth:</strong> {customer.place_of_birth}<br />
          <strong>Date of Birth:</strong> {customer.date_of_birth}<br />
          <strong>Full Address:</strong> {customer.full_address}<br />
          <strong>Phone Number:</strong> {customer.phone_number}<br />
          <strong>Email:</strong> {customer.email}<br />
          <strong>Nationality:</strong> {customer.nationality}<br />
          <strong>Postcode:</strong> {customer.postcode}<br />
          <strong>Religion:</strong> {customer.religion}<br />
          <strong>Gender:</strong> {customer.gender}<br />
          <strong>City/Country:</strong> {customer.city_country}<br />
          <strong>Role:</strong> {customer.role}<br />
        </div>
      )}
      {!customer && <div>Loading...</div>}
      {/* Render the MembershipFormList component and pass the fetched forms data as props */}
      
      {/* Add a link to navigate back to the project page */}
      <Link to="/" className="back-link">Back to Project</Link>
    </div>
  );
};

export default CustomerDetails;
