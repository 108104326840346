import React, { useState, useEffect } from 'react';
import axios from 'axios';

function TeamMembers({ teamId }) {
  const [members, setMembers] = useState([]);

  useEffect(() => {
    fetchMembers();
  }, [teamId]);

  const fetchMembers = async () => {
    try {
      const response = await axios.get(`https://217.21.84.160:3001/teams/${teamId}/members`);
      setMembers(response.data);
    } catch (error) {
      console.error('Error fetching team members:', error);
    }
  };

  return (
    <div>
      <h3>Team Members</h3>
      <ul>
        {members.map(member => (
          <li key={member.id}>{member.name}</li>
        ))}
      </ul>
    </div>
  );
}

export default TeamMembers;
