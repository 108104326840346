import React, { useState } from 'react';

const TicketGenerator = ({ fetchTicketsData }) => {
  const [ticketDetails, setTicketDetails] = useState({
    title: '',
    description: ''
  });
  const [error, setError] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setTicketDetails({ ...ticketDetails, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('https://217.21.84.160:3001/tickets', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`
        },
        body: JSON.stringify(ticketDetails)
      });

      if (!response.ok) {
        throw new Error('Failed to generate ticket');
      }

      console.log('Ticket generated successfully');
      if (typeof fetchTicketsData === 'function') {
        fetchTicketsData(); // Fetch updated ticket data after generating a new ticket
      }

      // Clear input fields and error state
      setTicketDetails({ title: '', description: '' });
      setError('');
    } catch (error) {
      console.error('Error generating ticket:', error);
      setError('Failed to generate ticket');
    }
  };
  
  return (
    <div className="ticket-generator">
      <h2>Generate Ticket</h2>
      {error && <p>{error}</p>}
      <form onSubmit={handleSubmit}>
        <div>
          <label>Title:</label>
          <input
            type="text"
            name="title"
            value={ticketDetails.title}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label>Description:</label>
          <textarea
            name="description"
            value={ticketDetails.description}
            onChange={handleChange}
            required
          ></textarea>
        </div>
        <button type="submit">Generate Ticket</button>
      </form>
    </div>
  );
};

export default TicketGenerator;
