import React, { useEffect, useState } from 'react';
import TicketGenerator from './TicketGenerator';

const Dashboard = ({ teamId }) => {
  const [liveProjectsData, setLiveProjectsData] = useState([]);
  const [tickets, setTickets] = useState([]);

  useEffect(() => {
    fetchLiveProjectsData();
    fetchTicketsData(); // Fetch tickets data
  }, []);

  const fetchLiveProjectsData = async () => {
    try {
      const response = await fetch('https://217.21.84.160:3001/membership_form/website_links', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      });
      if (!response.ok) {
        throw new Error('Failed to fetch live projects data');
      }
      const data = await response.json();
      setLiveProjectsData(data);
    } catch (error) {
      console.error('Error fetching live projects data:', error);
    }
  };

  const generateTicket = async (ticketDetails) => {
    try {
      const response = await fetch('https://217.21.84.160:3001/tickets', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`
        },
        body: JSON.stringify(ticketDetails)
      });
  
      if (!response.ok) {
        throw new Error('Failed to generate ticket');
      }
  
      console.log('Ticket generated successfully');
      if (typeof fetchTicketsData === 'function') {
        fetchTicketsData(); 
      }
    } catch (error) {
      console.error('Error generating ticket:', error);
      throw error; 
    }
  };
  

  const fetchTicketsData = async () => {
    try {
      const response = await fetch('https://217.21.84.160:3001/tickets');
      if (!response.ok) {
        throw new Error('Failed to fetch tickets');
      }
      const data = await response.json();
      setTickets(data); // Assuming setTickets is a state updater function
    } catch (error) {
      console.error('Error fetching tickets data:', error.message);
    }
  };

  return (
    <div className="container-fluid px-4">
      <div className="row">
        <div className="col-lg-8">
          <div className="card mb-4">
            <div className="card-header">
              <h5 className="card-title">Live Projects</h5>
            </div>
            <div className="card-body">
              <ul className="list-group">
                {liveProjectsData.length > 0 ? (
                  <div className="table-responsive">
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th scope="col">Project Name</th>
                          <th scope="col">Development Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {liveProjectsData.map((project, index) => (
                          <tr key={index}>
                            <td className="fw-bold">{project.website_link}</td>
                            <td>{project.development_status}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <li className="list-group-item">Loading live projects...</li>
                )}
              </ul>
            </div>
          </div>
        </div>
        <div className="col-lg-4">
          <div className="card mb-4">
            <div className="card-header">
              <h5 className="card-title">Ticket Data</h5>
            </div>
            <div className="card-body">
              <ul className="list-group">
                {tickets.map((ticket, index) => (
                  <li key={index} className="list-group-item">
                    <div><strong>Title:</strong> {ticket.title}</div>
                    <div><strong>Description:</strong> {ticket.description}</div>
                    <div><strong>Generated By:</strong> {ticket.generatedBy}</div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <TicketGenerator generateTicket={generateTicket} fetchTicketsData={fetchTicketsData} />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
