import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, Link } from 'react-router-dom';
import logo from './Logo.png';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import Project from './Component/Project';
import CreateNewProject from './Component/Createnewproject';
import LiveProject from './Component/LiveProject';
import CustomerDetails from './Component/CustomerDetails';
import Dashboard from './Component/Dashboard';
import Login from './Component/Login';
import Signup from './Component/Signup';
import Teams from './Component/Teams';
import UserManagement from './Component/UserManagement';
  



function Header({ isLoggedIn, name, userRole }) {
  const [dropdownVisible, setDropdownVisible] = useState(false);

  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const handleLogout = () => {
    const confirmLogout = window.confirm("Are you sure you want to logout?");
    if (confirmLogout) {
      console.log('Logged out');
      alert("Logout successful!");
      // Redirect to the login page
      window.location.href = "/login";
    }
  };

  const Navbar = () => {
    return (
      <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <div className="container">
          <a className="navbar-brand" href="#">
            <img src="logo.png" alt="Logo" />
          </a>
        </div>
      </nav>
    );
  };

  return (
    <header className="header">
      <div className="logo"> 
        <img src={logo} alt="Techcraftery Logo" />
      </div>
      <div className="navigation">
        <ul>
          {isLoggedIn && (
            <>
              <li><Link to="/LiveProject">Live Projects</Link></li>
              <li><Link to="/project">Customer Details</Link></li>
              <li><Link to="/dashboard">Dashboard</Link></li>
              <li><Link to="/teams">Teams</Link></li>
              {userRole === 'superadmin' && <li><Link to="/usermanagement">User Management</Link></li>}
              <li><Link to="/createnewproject">Create New Project</Link></li>
              <li className="admin-dropdown" onClick={toggleDropdown}>
                <div className="admin-name">
                  <span>Hello, {name}</span>
                  <i className={`fas ${dropdownVisible ? 'fa-chevron-up' : 'fa-chevron-down'}`}></i>
                </div>
                {dropdownVisible && (
                  <ul className="admin-menu">
                    <li onClick={handleLogout}>Logout</li>
                  </ul>
                )}
              </li>
            </>
          )}
        </ul>
      </div>
    </header>
  );
}

function Footer() {
  return (
    <footer className="footer">
      <p>&copy; 2024 Techcraftery. All rights reserved.</p>
    </footer>
  );
}

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [name, setName] = useState("");
  const [userRole, setUserRole] = useState("");
  const [liveProjectsData, setLiveProjectsData] = useState([]);

  return (
    <Router>
      <div className="App">
        <Header isLoggedIn={isLoggedIn} name={name} userRole={userRole} />
        <main className="main">
          <Routes>
            <Route path="/signup" element={<Signup />} />
            <Route
              path="/"
              element={isLoggedIn ? <Navigate to="/dashboard" /> : <Navigate to="/login" />}
            />
            <Route path="/login" element={<Login setIsLoggedIn={setIsLoggedIn} setName={setName} setUserRole={setUserRole} />} />
            {isLoggedIn && (
              <>
                <Route path="/LiveProject" element={<LiveProject setLiveProjectsData={setLiveProjectsData} />} />
                <Route path="/project" element={<Project />} />
                <Route path="/createnewproject" element={<CreateNewProject />} />
                <Route path="/customer/:id" element={<CustomerDetails />} />
                <Route path="/dashboard" element={<Dashboard liveProjectsData={liveProjectsData} />} />
                <Route path="/teams" element={<Teams />} />
                <Route path="/usermanagement" element={<UserManagement />} />
                {/* Integrate Chat component */}
                
              </>
            )}
          </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
