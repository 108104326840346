import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './project.css';

const Project = () => {
  const [customerDetails, setCustomerDetails] = useState([]);

  useEffect(() => {
    const fetchCustomerDetails = async () => {
      try {
        const response = await fetch('https://217.21.84.160:3001/membership_forms');
        
        if (response.ok) {
          const data = await response.json();
          setCustomerDetails(data);
        } else {
          console.error('Failed to fetch customer details:', response.statusText);
        }
      } catch (error) {
        console.error('Network error:', error.message);
      }
    };

    fetchCustomerDetails();
  }, []);

  return (
    <div className="project-page">
      <h1>Customer Details</h1>
      {customerDetails.length === 0 ? (
        <p>Loading...</p>
      ) : (
        <table>
          <thead>
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th>Website Link</th>
              <th>Email</th>
              <th>Phone Number</th>
              <th>Address</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {customerDetails.map((customer) => (
              <tr key={customer.id}>
                <td>{customer.id}</td>
                <td>{customer.first_name}</td>
                <td>{customer.website_link}</td>
                <td>{customer.email}</td>
                <td>{customer.phone_number}</td>
                <td>{customer.full_address}</td>
                <td>
                  <Link to={`/customer/${customer.id}`}>Info</Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default Project;
